<template>
    <div id="nt_wrapper">

        <Header/>

        <div v-if="article" id="nt_content">

            <!--category heading-->
            <div class="kalles-section page_section_heading">
                <div class="page-head tc pr oh art_bg_img page_head_article-heading">
                    <div class="parallax-inner nt_parallax_true lazyload nt_bg_lz pa t__0 l__0 r__0 b__0" data-bgset="/img/banner-009.jpeg"></div>
                    <div class="container pr z_100">
                        <h1 class="tu cw mb__10">{{article.title}}</h1>
                        <time class="entry-date published updated f__libre" :datetime="article.created_time">{{article.created_time}}</time>
                        <div class="pr mt__10">
                            <span v-if="article.author" class="post-author mr__15">作者：<span v-if="article.author_url" class="cd">{{article.author}}</span><a :href="article.author_url" class="cd">{{article.author_url}}</a></span>
                            <span v-if="article.category" class="mr__15">分类：<a :href="'/article/list/' + article.article_category_id" class="cd">{{article.category.title}}</a></span>
                            <span v-if="false" class="comment-number pr"><a href="#comments">6 评论</a></span>
                        </div>
                    </div>
                </div>
            </div>
            <!--end category heading-->

            <!--post content-->
            <div class="container mt__40 mb-0">
                <div class="row nt_single_blog">
                    <div class="col-md-12 col-xs-12">
                        <div id="kalles-section-article-template" class="kalles-section type_carousel">
                            <div class="post-content mt__50 inl_cnt_js">
                                <article class="post type-post" v-html="article.detail"></article>

                                <!--tag comment-->
                                <div v-if="false" class="tag-comment mt__40">
                                    <div class="row al_center">
                                        <div class="post-tags col-12 col-md tc tl_md">
                                            <i class="facl facl-tags mr__20"></i>
                                            <a href="blog-grid.html">Beauty</a>
                                            , <a href="blog-grid.html">Fashion</a>
                                        </div>
                                        <div v-if="false" class="comments-link col-12 col-md-auto tc tr_md"><a href="#comments">6 评论</a></div>
                                    </div>
                                </div>
                                <!--end tag comment-->

                                <!--Social media-->
                                <div v-if="false" class="social-share tc mt__40 social_des_2">
                                    <div class="nt-social"><a href="https://www.facebook.com" class="facebook cb ttip_nt tooltip_top"><span class="tt_txt">Share on Facebook</span><i class="facl facl-facebook"></i></a><a href="https://twitter.com" class="twitter cb ttip_nt tooltip_top"><span class="tt_txt">Share on Twitter</span><i class="facl facl-twitter"></i></a><a href="https://www.google.com/gmail/about" class="email cb ttip_nt tooltip_top"><span class="tt_txt">Share on Email</span><i class="facl facl-mail-alt"></i></a><a href="https://www.pinterest.com/" class="pinterest cb ttip_nt tooltip_top"><span class="tt_txt">Share on Pinterest</span><i class="facl facl-pinterest"></i></a><a href="https://www.tumblr.com/" class="tumblr cb ttip_nt tooltip_top"><span class="tt_txt">Share on Tumblr</span><i class="facl facl-tumblr"></i></a><a href="https://telegram.org/" class="telegram cb ttip_nt tooltip_top"><span class="tt_txt">Share on Telegram</span><i class="facl facl-telegram"></i></a></div>
                                </div>
                                <!--end social media-->

                                <!--navigation-->
                                <div class="blog-navigation mt__60 fs__40 tc">
                                    <a v-if="article.article_prev" :href="'/article/item/' + article.article_prev.article_id" class="pl__30 pr__30 cd chp ttip_nt tooltip_top_right"><i class="pegk pe-7s-angle-left"></i><span class="tt_txt">{{article.article_prev.title}}</span></a>
                                    <a v-if="article.category" :href="'/article/list/' + article.article_category_id" class="pl__30 pr__30 cd chp ttip_nt tooltip_top"><i class="pegk pe-7s-keypad"></i><span class="tt_txt">返回 {{article.category.title}} 列表</span></a>
                                    <a v-if="article.article_next" :href="'/article/item/' + article.article_next.article_id" class="pl__30 pr__30 cd chp ttip_nt tooltip_top_left"><i class="pegk pe-7s-angle-right"></i><span class="tt_txt">{{article.article_next.title}}</span></a>
                                </div>
                                <!--end navigation-->

                                <!--related post-->
                                <div v-if="false" class="post-related mt__50">
                                    <h4 class="mg__0 mb__50 tu tc fwb">相关文章</h4>
                                    <div class="nt_slider row nt_cover ratio4_3 position_8 equal_nt js_carousel nt_slider prev_next_0 btn_owl_1 dot_owl_1 dot_color_1 btn_vi_2" data-flickity='{"imagesLoaded": 0,"adaptiveHeight": 1, "contain": 1, "groupCells": "100%", "dragThreshold" : 5, "cellAlign": "left","wrapAround": false,"prevNextButtons": true,"percentPosition": 1,"pageDots": false, "autoPlay" : 0, "pauseAutoPlayOnHover" : true, "rightToLeft": false }'>
                                        <div class="item col-lg-4 col-md-4 col-12 pr_animated_done"><a class="db mb__20 lazyload nt_bg_lz pr_lazy_img" href="blog-post-with-instagram-shop.html" data-bgset="assets/images/blog-page/blog-slide-01.jpg"></a>
                                            <h5 class="mg__0 fs__14 mb__5 d-block"><a class="cd chp" href="blog-post-with-instagram-shop.html">The Easiest Way to Break Out on Top</a></h5>
                                            <span class="f__libre">
                                        <time datetime="2020-04-06T02:22:00Z">April 6, 2020</time>
                                        </span></div>
                                        <div class="item col-lg-4 col-md-4 col-12 pr_animated_done"><a class="db mb__20 lazyload nt_bg_lz pr_lazy_img" href="blog-post-with-instagram-shop.html" data-bgset="assets/images/blog-page/blog-slide-03.jpg"></a>
                                            <h5 class="mg__0 fs__14 mb__5 d-block"><a class="cd chp" href="blog-post-with-instagram-shop.html">Style for couple in Weeding season</a></h5>
                                            <span class="f__libre">
                                        <time datetime="2020-04-06T02:22:00Z">April 6, 2020</time>
                                        </span></div>
                                        <div class="item col-lg-4 col-md-4 col-12 pr_animated_done"><a class="db mb__20 lazyload nt_bg_lz pr_lazy_img" href="blog-post-with-instagram-shop.html" data-bgset="assets/images/blog-page/blog-slide-04.jpg"></a>
                                            <h5 class="mg__0 fs__14 mb__5 d-block"><a class="cd chp" href="blog-post-with-instagram-shop.html">Cool Spring Street Style Looks</a></h5>
                                            <span class="f__libre">
                                        <time datetime="2020-04-06T02:22:00Z">April 6, 2020</time>
                                        </span></div>
                                        <div class="item col-lg-4 col-md-4 col-12 pr_animated_done"><a class="db mb__20 lazyload nt_bg_lz pr_lazy_img" href="blog-post-with-instagram-shop.html" data-bgset="assets/images/blog-page/blog-slide-05.jpg"></a>
                                            <h5 class="mg__0 fs__14 mb__5 d-block"><a class="cd chp" href="blog-post-with-instagram-shop.html">Style Advice All Men Should Hear</a></h5>
                                            <span class="f__libre">
                                        <time datetime="2020-04-06T02:22:00Z">April 6, 2020</time>
                                        </span></div>
                                        <div class="item col-lg-4 col-md-4 col-12 pr_animated_done"><a class="db mb__20 lazyload nt_bg_lz pr_lazy_img" href="blog-post-with-instagram-shop.html" data-bgset="assets/images/blog-page/blog-slide-06.jpg"></a>
                                            <h5 class="mg__0 fs__14 mb__5 d-block"><a class="cd chp" href="blog-post-with-instagram-shop.html">101 Beauty Tips Every Girl Should Know</a></h5>
                                            <span class="f__libre">
                                        <time datetime="2020-04-06T02:22:00Z">April 6, 2020</time>
                                        </span></div>
                                    </div>
                                </div>
                                <!--end related post -->

                                <!--comments block-->
                                <div v-if="false" class="mt__40 comments_block">
                                    <hr>
                                    <h2 class="mg__0 mb__30 tu fs__20">6 thoughts on “<span>Spring – Summer Trending 2020</span>”</h2>
                                    <div id="comments" class="row">
                                        <div class="comment col-12 mb__30">
                                            <div class="row al_center align-items-start">
                                                <div class="col-auto col_avatar_cm"><img alt="avatar" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="assets/images/blog-page/user-avatar-02.jpeg" class="comment__avatar lazyload"></div>
                                                <div class="col">
                                                    <h5 class="comment__author mg__0 mb__5 tu cb fs__13">SELENA RICHARDSON</h5>
                                                    <div class="comment__content rte">
                                                        <p>Parturient odio mollis condimentum parturient a eu scelerisque auctor a convallis blandit mi vestibulum odio id rhoncus feugiat laoreet.</p>
                                                    </div>
                                                    <div class="comment__date f__libre fs__12">April 12, 2020 at 16:12pm</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="comment col-12 mb__30">
                                            <div class="row al_center align-items-start">
                                                <div class="col-auto col_avatar_cm"><img alt="avatar" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="assets/images/blog-page/no-avatar.jfif" class="comment__avatar lazyload"></div>
                                                <div class="col">
                                                    <h5 class="comment__author mg__0 mb__5 tu cb fs__13">sfsf sa</h5>
                                                    <div class="comment__content rte">
                                                        <p>smksj a;orw</p>
                                                    </div>
                                                    <div class="comment__date f__libre fs__12">January 8, 2021 at 01:00am</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="comment col-12 mb__30">
                                            <div class="row al_center align-items-start">
                                                <div class="col-auto col_avatar_cm"><img alt="avatar" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="assets/images/blog-page/no-avatar.jfif" class="comment__avatar lazyload"></div>
                                                <div class="col">
                                                    <h5 class="comment__author mg__0 mb__5 tu cb fs__13">dfdsf</h5>
                                                    <div class="comment__content rte">
                                                        <p>sfa ff</p>
                                                    </div>
                                                    <div class="comment__date f__libre fs__12">January 8, 2021 at 00:59am</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="comment col-12 mb__30">
                                            <div class="row al_center align-items-start">
                                                <div class="col-auto col_avatar_cm"><img alt="avatar" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="assets/images/blog-page/no-avatar.jfif" class="comment__avatar lazyload"></div>
                                                <div class="col">
                                                    <h5 class="comment__author mg__0 mb__5 tu cb fs__13">Jamshaid Farooqui</h5>
                                                    <div class="comment__content rte">
                                                        <p>Test mail</p>
                                                    </div>
                                                    <div class="comment__date f__libre fs__12">July 31, 2020 at 17:39pm</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="comment col-12 mb__30">
                                            <div class="row al_center align-items-start">
                                                <div class="col-auto col_avatar_cm"><img alt="avatar" src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" data-src="assets/images/blog-page/user-avatar-01.jpeg" class="comment__avatar lazyload"></div>
                                                <div class="col">
                                                    <h5 class="comment__author mg__0 mb__5 tu cb fs__13">MONICA JORK</h5>
                                                    <div class="comment__content rte">
                                                        <p>Lorem ipsum dolor sit amet, lacus eu erat integer bibendum rutrum, sed arcu molestie, in quis ornare, rhoncus scelerisque velit, nam feugiat nibh leo. Ac suspendisse turpis posuere, cursus fames eu ege</p>
                                                    </div>
                                                    <div class="comment__date f__libre fs__12">April 12, 2020 at 16:09pm</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mt__20">
                                            <nav class="nt-pagination w__100 tc ">
                                                <ul class="pagination-page page-numbers">
                                                    <li><span class="page-numbers current">1</span></li>
                                                    <li><a class="page-numbers" href="#">2</a></li>
                                                    <li><a href="#" class="next page-numbers">Next</a></li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <hr class="mt-0">
                                    <form method="post" action="#" id="comment_form" class="comment-form">
                                        <h2 class="mg__0 h3 fs__16 mb__5 tu">Leave a comment</h2>
                                        <p class="comment-notes mb__30">Your email address will not be published. Required fields are marked<span class="required">*</span></p>
                                        <div class="row">
                                            <div class="col-12 col-md-6 mb__25">
                                                <label for="CommentForm-author">Name <span class="required">*</span></label>
                                                <input type="text" name="author" id="CommentForm-author" class="input-full" value="">
                                            </div>
                                            <div class="col-12 col-md-6 mb__25">
                                                <label for="CommentForm-email">Email <span class="required">*</span></label>
                                                <input type="email" name="email" id="CommentForm-email" class="input-full" value="" autocomplete="">
                                            </div>
                                            <div class="col-12 mb__25">
                                                <label for="CommentForm-body">Comment<span class="required">*</span></label>
                                                <textarea cols="45" rows="8" name="comment_body" id="CommentForm-body" class="input-full"></textarea>
                                            </div>
                                        </div>
                                        <input type="submit" class="button" value="Post Comment">
                                    </form>
                                </div>
                                <!--end comments block-->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--end post content-->

        </div>

        <Footer/>

    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import {getCurrentInstance, inject, onMounted, onUnmounted, reactive, ref} from "vue";
import '/public/css/drift-basic.min.css'
import '/public/css/photoswipe.css'
import '/public/css/single-blog.css'
import {getArticleV1Api} from "@/api/article/article";

export default {
    name: 'ArticleItem',
    components: {Header, Footer},
    setup() {
        const {proxy} = getCurrentInstance();

        const website = inject('website');

        const options = reactive({
            title: '新闻',
            name: proxy.$route.name,
            url: proxy.$route.path,
        });

        const article_category_list = inject('article_category_list');

        const article = ref();
        const apiArticle = () => {
            return new Promise(function (resolve) {
                getArticleV1Api(proxy.$route.params.id).then(res => {
                    if (res.data.code == 0) {
                        article.value = res.data.data;
                        article.value.category = article_category_list.value.find(o => o.article_category_id == article.value.article_category_id);
                    }

                    resolve(true);
                });
            });
        };

        // 初始数据加载
        Promise.all([apiArticle()]).then(() => {
            // apiProductList().then(() => {
            //     mainNotyConfirm.close();
            // });

            console.log(JSON.stringify(article.value))
        });

        const loadScript = (url) => {
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.src = url;
            document.getElementsByTagName("body")[0].appendChild(script);
        }
        onMounted(() => {
            document.getElementsByTagName('body')[0].className = 'kalles-template single-product-template zoom_tp_2 header_full_true des_header_3 css_scrollbar lazy_icons btnt4_style_2 template-index kalles_toolbar_true hover_img2 swatch_style_rounded swatch_list_size_small label_style_rounded wrapper_full_width hide_scrolld_true lazyload'

            setTimeout(function() {
                loadScript("/js/flickity.pkgd.min.js");
                loadScript("/js/interface.js");
            }, 500);
        })
        onUnmounted(() => {
            document.body.removeAttribute('class')
        })

        return {website, options, article}
    },
}
</script>
